'use strict';

const banners = document.querySelectorAll('.js-wrapped-banner');

banners.forEach(function (banner) {
    const button = banner.querySelector('.b-actions-item a');

    if (button) {
        const url = button.getAttribute('href');

        if (url) {
            const linkWrapper = document.createElement('a');

            linkWrapper.setAttribute('href', url);
            linkWrapper.className = 'c-banner__link';

            const image = banner.querySelector('img');

            if (image && image.getAttribute('alt')) {
                const altText = image.getAttribute('alt');

                linkWrapper.setAttribute('aria-label', altText);
                linkWrapper.setAttribute('title', altText);
            }

            linkWrapper.setAttribute('target', '_self');
            linkWrapper.setAttribute('rel', 'noopener');

            banner.parentNode.insertBefore(linkWrapper, banner);
            linkWrapper.appendChild(banner);
        }
    }
});
